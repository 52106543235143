import {remoteCustomersDb} from './serverconfig';
import {createDate} from './help-functions';

const pathName = window.location.pathname

export function authenticate(action){

  const result = document.getElementById('result')

  // const loginform = document.getElementById('loginform')

  result.innerHTML = 'We kijken even of je nog ingelogd bent...';
  
  function notLoggedIn(message){
    if(window.location.pathname!=='/'){
      window.location='/?origin=' + pathName;
    }
    // loginform.style.display = 'block';
    result.innerHTML = message;
  }
  
  let userStatus;
  remoteCustomersDb.getSession(function (err, response) {

    let message
    let roles
    let name

    if (err) {
      if (err.name === 'unauthorized') {
        message = 'Je bent niet ingelogd.';
        notLoggedIn(message);
      } else {
        console.log(err);
        message = 'Er was een probleem met inloggen. Check de verbinding en refresh de browser.';
        // notLoggedIn(message);
      }
          //notLoggedIn(message);
    } else if (!response.userCtx.name) {  
      console.log('not logged in')
      message = 'Je bent niet ingelogd.';
      notLoggedIn(message);
    } else {
      name = response.userCtx.name
      result.innerHTML = 'Hello ' + name
      setTimeout(function(){ result.style.display = 'none'; }, 2000);
      roles = response.userCtx.roles
      // set day when user was authenticated to be used in checkAuthentication
      const time = new Date()
      const date = createDate(time)
      window.localStorage.setItem('D', date)

       
      if (!!action) {
        action(roles, name);
      }
       
      return roles
    }
  })
}

export function logout(){
  remoteCustomersDb.logout(function (err, response) {
    if (err) {
      console.log(err);
    } else {
      window.location='/';
    }
  });
}


//The below function gets called from app.js, planning.js etc in order check if there is still a valid session.
export function checkAuthentication () {
  
  const target = window.localStorage.getItem('D') || ""
  
  const now = createDate(new Date())

  if (now > target) {
    console.log('need to reauthenticate')
    authenticate()
  }

}