import {database} from '../../config-frontend'

// const dev = false

const remoteDbLocation = database.remoteDbLocation
const planningDbPrefix = database.names.planningDbPrefix
const customerDbName = database.names.customersDb
const conflictsDbName = database.names.conflictsDb
const reservationDbName = database.names.reservationDb


const designDoc = {
  "_id": "_design/view",
  "views": {
    "appointments": {
      "map": "function (doc) {\n if (doc._id.charAt(0)=== \"a\" || doc._id.charAt(0)=== \"r\") {emit(doc.date, doc)};\n}"
    },
    "questionnaire": {
      "map": "function (doc) {\n if (doc.nr == doc.course.nrLessons && !!doc.date && !!doc.position){ emit(doc.date, doc.customers)}\n}"
    },
    "reminder": {
      "map": "function (doc) {\n if (doc.nr === 1 && !doc.reminded && !!doc.position){ emit(doc.date, doc)}\n}"
    },
    "byCustomerId": {
      "map": "function (doc) {\n if (doc.customers){ \n for (i=0;i<doc.customers.length;i++){ emit(doc.customers[i], doc)}}\n}"
    }
  },
  "language": "javascript"
}


const createRemotePlanningDb = async function (season, param) {
  let db =  new PouchDB(remoteDbLocation+planningDbPrefix+season, param)
  if (!param.skipSetup){
    let designdoc = await db.put(designDoc)

    //set roles to db
    const setRoles = function () {
      let url = remoteDbLocation + planningDbPrefix + season +'/_security'
      let data = {
        'admins':{
          'roles':['_admin']
        },
        'members':{
          'roles':['backoffice','frontoffice','kite','server']
        }
      }

      let dataJSON = JSON.stringify(data)
      console.log(url, dataJSON)
      let httpRequest = new XMLHttpRequest();
      if (!httpRequest) {
        console.log('Cannot create an XMLHTTP instance');
        return false;
      }
      httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState === XMLHttpRequest.DONE) {
          if (httpRequest.status === 200) {
            /*the result is in httpRequest.responseText*/
            

          } else {
            console.log('There was a problem with the request.');
          }
        }
      };
      httpRequest.open('PUT', url);
      
      httpRequest.send(dataJSON);
    }

    setRoles()
  }
    // console.log(designdoc)
  return db
}

const currentSeason = new Date().getFullYear()


const localPlanningDbName = planningDbPrefix + currentSeason;

const remoteCustomersDb = new PouchDB(remoteDbLocation + customerDbName, {skipSetup: true});
const remotePlanningDb = new PouchDB(remoteDbLocation + planningDbPrefix + currentSeason, {skipSetup: true})
// const remotePlanningDb2022 = new PouchDB(remoteDbLocation + planningDbPrefix + '2022', {skipSetup: true})
const remoteReservationDb = new PouchDB(remoteDbLocation + reservationDbName, {skipSetup: true})

const localCustomersDb = new PouchDB(customerDbName, {size: 50});
const localPlanningDb = new PouchDB(localPlanningDbName, {size: 50}); 
const localReservationDb = new PouchDB(reservationDbName, {skipSetup: true})

const conflictsDb = new PouchDB(remoteDbLocation + conflictsDbName, {skipSetup: true})

const userDb = new PouchDB(remoteDbLocation + '_users', {skipSetup: true})

// var toConvertDb = new PouchDB(remoteDbLocation+'toconvert', {skipSetup: true, ajax: {timeout: 150000}})
// var convertDb = new PouchDB(remoteDbLocation+'customers_converted', {skipSetup: true, ajax: {timeout: 150000}})

export {
  localCustomersDb, 
  remoteCustomersDb, 
  remotePlanningDb,  
  localPlanningDb, 
  createRemotePlanningDb, 
  planningDbPrefix, 
  conflictsDb, 
  localReservationDb, 
  remoteReservationDb,
  userDb
};
