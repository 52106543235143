export function getParameterByName(name, url) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  var results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


export function currentDate(){
    var now = new Date();
    return createDate(now)
}

export function createDate(date) {
  const dateObject = new Date(date)
  const day = addZero(dateObject.getDate());
  const month = addZero(dateObject.getMonth() + 1);
  const year = dateObject.getFullYear();
  return year+'-'+month+'-'+day;
}

export function addDay(date, nr) {
  let day = new Date(date)
  day.setDate(day.getDate() + nr)
  return day
}

export function substractDay(date, nr) {
  let day = new Date(date)
  day.setDate(day.getDate() - nr)
  return day
}

export function convertDateDMY(dateAsISOString){
  return dateAsISOString.slice(0,10).split('-').reverse().join('-')
}

export function convertDate(date) {
  let year = date.getFullYear().toString();
  let month = (date.getMonth()+1).toString();
  if (month < 10) {
    month = '0'+month;
  }
  var day = date.getDate();
  if (day < 10) {
    day = '0'+day;
  }
return year+'-'+month+'-'+day;
}

export function readableDate(dateString, language, type) { // input: yyyy-mm-dd
  const date = new Date (dateString)
  const month = date.getMonth()
  const day = date.getDate()
  const weekday = date.getDay()

  let days
  let months

  if (!!type && type === 'short') {

    days = {
      en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      nl: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
      de: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
    }

    months = {
      en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      nl: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
      de: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
    }

  } else {

    days = {
      en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      nl: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
      de: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
    }

    months = {
      en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      nl: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
      de: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
    }
  }

  const lan = language? language : 'en'

  if (lan === 'nl' || lan === 'de') {
    return days[lan][weekday] + ' ' + day + ' ' + months[lan][month]
  } else {
    return days[lan][weekday] + ' ' + months[lan][month] + ' ' + day
  }

}

export function reverseDate(date) { //to reverse 2022-06-19 to 19-06-2022
  return date.split('-').reverse().join('-')
}

export function convertDateString(date) { //to convert 2022-06-19 to date object
  // const split = date.split
  // const day = parseInt(split[2])
  // const month = parseInt(split[1]) - 1
  // const year = parseInt(split[0]) + 2000
  return new Date(date)

}

export function createId(prefix){
    var currentTime = new Date();
    var year = currentTime.getFullYear();
    var month = addZero(currentTime.getMonth() + 1);
    var day = addZero(currentTime.getDate());
    var hours = addZero(currentTime.getHours());
    var minutes = addZero(currentTime.getMinutes());
    var seconds = addZero(currentTime.getSeconds());
    var milliSeconds = currentTime.getMilliseconds();
    var id = prefix+year+month+day+hours+minutes+seconds+milliSeconds; 
    return id;
}

export function createCustomerId(firstname, lastname){
  let milliSeconds = new Date().getMilliseconds()
  let id = ('c_'+firstname+lastname+milliSeconds).split(' ').join('').toLowerCase()
  return id
}

export function idPlusOne(oldId){
    var series = oldId.slice(0,-1)
    var newLastnr = parseInt(oldId.slice(-1))+1
    var newId = series+newLastnr
    return newId
}

export function checkCompleteness(customer){
    let count = 0
    if(customer.agree != true){
        count ++;
        console.log('not agreed')}
    if(!customer.birthYear ){
        count ++; 
        console.log('no age ')}
    if(!customer.email     ){
        count ++; 
        console.log('no email')}
    if(!customer.firstname ){
        count ++; 
        console.log('no first name')}
    // if(!customer.found     ){
    //     count ++; 
    //     console.log('no found')}
    // if(!customer.insurance ){
    //     count ++; 
    //     console.log('no insurance checked')}
    if(!customer.language  ){
        count ++; 
        console.log('no language')}
    if(!customer.lastname  ){
        count ++; 
        console.log('no last name')}
    if(!customer.phone     ){
        count ++; 
        console.log('no phone')}

    if(count > 0){
        return false
    } else {
        return true
    }
}

export function age(customer) {
  // calculate age. note that birthMonth is human month, not js month (5 is may, not june)

  if (!customer.birthYear && customer.adult != 'yes') {
    return '?'
  } else if (customer.adult === 'yes' || customer.birthYear === 'adult') {
    return 'V'
  } else {

    const date = new Date()
    const month = date.getMonth()
    const year = date.getFullYear()
    
    if (!!customer.birthMonth) {
      return month < customer.birthMonth - 1? (year - customer.birthYear) -1 : year - customer.birthYear
    } else {
      return year - customer.birthYear
    }
  }
}

export function addZero(i) {
  if (i < 10) {
      i = '0' + i;
  }
  return i;
}

export function addZeros(i, l) {
  let iString = i.toString()
  while (iString.length < l) {
    iString = '0' + iString;
  }
  return iString;
}

export function startTime (startTime, interval, colNumber){
    var seasonStartTimeDecimal = parseInt(startTime.split(':')[0])+parseInt(startTime.split(':')[1])/60
    var intervalDecimal = interval/60
    var startTimeDecimal
    if (seasonStartTimeDecimal+(colNumber-1)*intervalDecimal >= seasonStartTimeDecimal+1){
        startTimeDecimal = seasonStartTimeDecimal+((colNumber-1)%(60/interval))*intervalDecimal
    } else {
        startTimeDecimal = seasonStartTimeDecimal+(colNumber-1)*intervalDecimal
    }
    var startHour = addZero(Math.floor(startTimeDecimal))
    var startMinutes = addZero((startTimeDecimal%1)*60)
    var columnStarttime = [startHour, startMinutes].join(':')
    
    return columnStarttime
}

export function sortAppointments(a, b) {
  let result
  if (a.date == b.date) {
    let aTime = !!a.position ? a.position.split('t')[1] : ''
    let bTime = !!b.position ? b.position.split('t')[1] : ''
    if ( aTime == bTime ) {
      result = 0
    } else if ( aTime > bTime ) {
      result = 1
    } else {
      result = -1
    }
  } else if (a.date > b.date) {
    result = 1
  } else if (!a.date && !!b.date) {
    result = 1
  } else {
    result = -1
  }
  return result
}

export function sortAppointmentsById(a, b) {
  const result = a._id > b._id ? 1 : -1
  return result
}

export function sortDays (a, b) {
  let result
  if (a.date == b.date) {
    result = 0
  } else if (a.date > b.date) {
    result = 1
  } else  {
    result = -1
  } 
  return result
}

export function sortFunction(key, order) {

  return function innerSort(a, b) {

    // if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
    //  /*property doesn't exist on either object*/
    //   return -1
    // }

    /* adapted so objects with non-existing props or empty prop always got to bottom */
    if ( !a.hasOwnProperty(key)) {
      a[key] = ''
    }

    if ( !b.hasOwnProperty(key) ) {
      b[key] = ''
    }

    const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key]
    const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if ( varA > varB) {
      comparison = 1
    } else if (varA < varB ) {
      comparison = -1
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    )
  }
}

export function calculateCoursePrice(course, numberOfCustomers) {
  if(course===undefined){
    return 0
  } else if (typeof course.price === 'number'){
    return course.price
  } else if (typeof course.price === 'object'){
    if (numberOfCustomers === 1) {
      return course.price.one
    } else if (numberOfCustomers === 2){
      return course.price.two || course.price.one
    } else {
      return course.price.three || course.price.two || course.price.one
    }
  }
}

// export function recalculateFees (course, oldLength, newLength) {
//   // console.log(oldLength, newLength)
//   // oldLength = parseInt(oldLength)
//   // newLength = parseInt(newLength)
//   const factor = newLength / oldLength

//   switch (course.feeType) {
//     case 'tier':
//       course.instructorFee.tier1Fee *= factor 
//       course.instructorFee.tier2Fee *= factor
//       break
//     case 'default':
//       course.instructorFee.one *= factor 
//       course.instructorFee.two *= factor
//       course.instructorFee.three *= factor
//       break
//     case 'fixed':
//       course.instructorFee *= factor
//       break
//     case 'flex':
//       course.instructorFee *= factor
//       break
//   }
  
//   return course
// }

export function stripCourse (course) {
  let newCourse = {
    _id: course._id,
    sport: course.sport,
    text: course.text,
    nrLessons: course.nrLessons,
    duration: course.duration,
    maxPersons: course.maxPersons,
    feeType: course.feeType,
    instructorFee: course.instructorFee,
    price: course.price
  }
  return newCourse
}

export function createColumn(date, position, start, interval){
  let column = {}
  column._id = date+'c'+addZero(position);
  column.position = position;
  column.startTime = startTime(start, interval, position);
  column.instructor = '';
  column.sports = [];
  column.primary = '';
  column.senior = false;
  column.bed = true;
  column.backup = false;
  return column
}

export function createRole(date, role){
  let column = {}
  column._id = date + role
  column.instructor = ''
  column.role = role
  column.bed = true
  return column
}

export function emailTest(email){
  let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return regex.test(email)
}

export function copyObject(object) {
  return JSON.parse(JSON.stringify(object));
}

export function mergeDedupe(arr) {
  return [...new Set([].concat(...arr))];
}

export function compareArrays(array1, array2) {
  if (array1.length != array2.length) {
    return false
  } else {
    const sorted1 = array1.sort()
    const sorted2 = array2.sort()

    return sorted1.every((val,i)=> val === sorted2[i])
  }
}

export function round(value, decimals) {
  let rounded = Number(Math.round(value+'e'+decimals)+'e-'+decimals);
  return rounded
}

export function debounce(func, timeout = 300){
  console.log('in the debounce', timeout)
  let timer;
  return (...args) => {
    console.log(timer)
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export function throttle (func, limit) {
    let waiting = false;                      // Initially, we're not waiting
    return function () {                      // We return a throttled function
        if (!waiting) {                       // If we're not waiting
            func.apply(this, arguments);  // Execute users function
            waiting = true;                   // Prevent future invocations
            setTimeout(function () {          // After a period of time
                waiting = false;              // And allow future invocations
            }, limit);
        }
    }
}