import {remoteCustomersDb} from './serverconfig';
import {authenticate} from './authenticate';
//var remoteCouch = new PouchDB(dbUrl, {skipSetup: true});


'use strict';

const name = document.getElementById('loginName');
const passWords = document.getElementById('passWord');
const result = document.getElementById('result');
const loginform = document.getElementById('loginform');

const query = window.location.search
const urlParams = new URLSearchParams(query)
const origin = urlParams.get('origin') || '/home'

//remoteCouch = new PouchDB('https://crm.windsurfschool.nl/couchdb/customers_dev_copy', {skipSetup: true})

authenticate(action)

function action(roles, name){
  console.log('in login action')
  if(roles.indexOf('instructor') > -1) {
    window.location='/instructeur'
  } else {
    window.location='/home';
  }  
}

function login(){
  
  const user = {
    name: name.value.toLowerCase().trim(),
    passWord: passWord.value
  };
  
  const ajaxOpts = {
    ajax: {
      headers: {
        Authorization: 'Basic ' + window.btoa(user.name + ':' +user.passWord)
      }
    }
  };


  /*The login function below leads to an error due to a double login. 
  (False: The first is while using ajaxOpts and then the actual login. 
  This won't work as the user just logged in). 
  Workaround was to use getSession instead, but that doesn't keep cookies. 
  Another workaround is applied to handle error (if user.name === error.name, then logged in).
  */
  remoteCustomersDb.login(user.name, user.passWord, ajaxOpts).then(function (result) {
    if (result.roles.indexOf('instructor') > -1 ) {
      window.location = '/instructeur'
    } else {
      window.location = origin;
    }
    console.log('logged in')
  }).catch(function(err){
    if (err.name === 'unauthorized') {
      console.log('error 401');
      result.innerHTML = 'Looks like you used the wrong username or password. Try again.'
    } else if(err.name === user.name){ //workaround to handle double login issue
      window.location= origin;
    } else {
      result.innerHTML = 'Something went wrong. Please refresh and try again.';
      console.log('error logging in: '+err.name)
    }
  });
}

loginform.addEventListener('submit', function(event){
    event.preventDefault();
    login()
});
