const prod = true

const environment = prod ? 'prod' : 'dev'

const databaseNames = {
  dev: {
    planningDbPrefix: 'planning_dev_',
    customersDb:'customers_development',
    reservationDb: 'reservations_dev',
    conflictsDb: 'conflicts_dev',
    mailDb: 'mails_dev',
    backupPrefix: 'dev_'
  },
  prod: {
    planningDbPrefix: 'planning_prod_',
    customersDb:'customers_production',
    reservationDb: 'reservations_prod',
    conflictsDb: 'conflicts_prod',
    mailDb: 'mails_prod',
    backupPrefix: 'prod_'
  }
}

const apiUrls = {
  getcustomerinfo: '/api/v1/get-customers-info',
  updateAllCustomers: '/api/v1/update-all-customers',
  updateCustomer: '/api/v1/update-customer',
  updateAppointmentRemark: '/api/v1/update-appointment-remark',
  getinstructor: '/api/v1/get-instructor',
  getAllInstructors: '/api/v1/get-all-instructors',
  getAppointmentsByInstructorId: '/api/v1/get-appointments-by-instructor',
  getAppointmentsByCustomers: '/api/v1/get-appointments-by-customers',
  instructorChanges: '/api/v1/listening',
  reservations: '/api/v1/reservations',
}


module.exports = {prod, databaseNames, apiUrls}