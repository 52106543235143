const {prod, databaseNames, apiUrls} = require('./config')

const environment = prod ? 'prod' : 'dev'

const remoteDbLocation = prod ?
        'https://crm.windsurfschool.nl/couchdb/' :
    'https://testcrm.windsurfschool.nl/couchdb/'    // 'http://localhost:5984/' for local development, 'https://testcrm.windsurfschool.nl/couchdb/' for remote
 
const database = {
  remoteDbLocation: remoteDbLocation,
  names: databaseNames[environment]
}

const authentication = {
  modus: 'on', // on, off or simulated
  simulatedRole: 'frontoffice'
}

const levelParams = {
  mediorLevelPrimary: 252,
  mediorLevelSecundary: 84,
  multiSportExcluded: ['sup', 'other'],
  mediorSupplement: 0.1,
  seniorSupplement: 0.3,
  multiSportSupplement: 0.1,
  maxSupplement: 0.3
}

module.exports = {authentication, prod, database, apiUrls, levelParams}
